<script>
import InfoTooltip from '@comp/InfoTooltip.vue'

export default {
  name: 'IndicatorGroup',
  components: {
    InfoTooltip,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'fas fa-question',
    },
    indicators: {
      type: Array,
      required: true,
      default: () => [
        {
          code: '',
          name: 'indicator',
          definition: 'This is an indicator',
          horizons: [],
          group: 'temp',
          selected: true,
        },
      ],
    },
  },
  data () {
    return {
      hoveredIndicator: null,
    }
  },
  computed: {
    indicatorsGroup () {
      if (this.indicators.every(indicator => indicator.selected)) {
        return true
      } else if (this.indicators.every(indicator => !indicator.selected)) {
        return false
      } else {
        return '?'
      }
    },
    indicatorsGroupIndeterminate () {
      return this.indicatorsGroup === '?'
    },
    areHorizonsDifferent () {
      return (new Set(this.indicators.map(i => i.horizons.map(h => h.period).join(',')))).size > 1
    },
    horizons () {
      const horizonsToStr = horizons => horizons.map(h => {
        const label = h.isRef ? this.$gettext('Reference') : this.$gettext('Projections')
        return `${label} ${h.period}`
      })
      if (this.areHorizonsDifferent) {
        if (this.hoveredIndicator) {
          return horizonsToStr(this.hoveredIndicator.horizons)
        } else {
          return []
        }
      } else {
        return horizonsToStr(this.indicators[0].horizons)
      }
    },
  },
  methods: {
    indicatorsGroupChanged () {
      if (this.indicatorsGroup && !this.indicatorsGroupIndeterminate) {
        this.indicators.forEach(indicator => {
          indicator.selected = false
        })
      } else {
        this.indicators.forEach(indicator => {
          indicator.selected = true
        })
      }
    },
    indicatorMouseOvered (indicator) {
      this.hoveredIndicator = indicator
    },
  },
}
</script>
<template>
  <div class="indicator-group">
    <div class="indicator-icon">
      <v-icon size="large">
        {{ icon }}
      </v-icon>
    </div>
    <div class="indicator-title">
      {{ name }}
    </div>
    <div class="legend">
      <div
        class="indicators-column"
        @mouseleave="indicatorMouseOvered(null)"
      >
        <v-checkbox
          :model-value="indicatorsGroup"
          :indeterminate="indicatorsGroupIndeterminate"
          color="primary"
          density="compact"
          hide-details="true"
          @click="indicatorsGroupChanged"
        >
          <template v-slot:label>
            <span class="column-title">
              {{ $gettext('Indicators') }}
            </span>
          </template>
        </v-checkbox>
        <ul>
          <li
            v-for="(indicator) in indicators"
            :key="indicator.code"
            class="indicator-item"
          >
            <v-checkbox
              v-model="indicator.selected"
              hide-details="true"
              density="compact"
              color="primary"
            >
              <template v-slot:label>
                <span
                  @mouseover="indicatorMouseOvered(indicator)"
                >
                  {{ $gettext(indicator.name) }}
                  <InfoTooltip
                    tooltip-position="end"
                    max-width="300px"
                  >
                    {{ $gettext(indicator.definition) }}
                  </InfoTooltip>
                </span>
              </template>
            </v-checkbox>
          </li>
        </ul>
      </div>
      <div class="horizons-column">
        <div class="horizon-title">
          {{ $gettext('Horizons') }}
        </div>
        <template v-if="horizons.length">
          <span
            v-for="horizon in horizons"
            :key="horizon"
            class="horizon"
          >
            {{ horizon }}
          </span>
        </template>
        <div
          v-else
          class="horizons-missing"
        >
          {{ $gettext('Hover an indicator to know their horizons') }}
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
/* stylelint-disable-next-line selector-class-pattern */
:deep(.indicator-item > .v-input > .v-input__control > .v-selection-control) {
  align-items: flex-start;
}
.indicator-group {
  display: grid;
  grid-template-areas:
    "icon title"
    "legend legend";
  gap: 0.5rem;
  grid-template-columns: 4ch auto;
}
.indicator-icon {
  grid-area: icon;
}
.indicator-title {
  grid-area: title;
  font-weight: bold;
  margin: auto 0;
}
.legend {
  grid-area: legend;
  display: grid;
  grid-template-areas:
    "column-title ."
    "indicators horizons";
  align-items: flex-start;
  justify-content: space-around;
}
.indicators-column {
  grid-area: indicators;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}
.horizons-column {
  grid-area: horizons;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20ch;
  border-left: 2px $secondary solid;
  padding-left: 10px;
}
.horizon {
  white-space: nowrap;
}
.horizons-missing {
  white-space: wrap;
  font-style: italic;
}
.horizon-title {
  font-weight: bold;
}
.indicator-item {
  display: flex;
}
:deep(.v-label) {
  margin-left: 0.5ch;
  gap: 0.5ch;
}
</style>
